import React from "react";
import myLogo from "../assets/images/logo.svg";
import headerFace from "../assets/images/header-face-img.png";
import { useRegistrationAndAuthContext } from "../context/RegistrationAndAuthContext";
import { Link, useNavigate } from "react-router-dom";
import Utils, { LABEL } from "../utils/constant";
import { showToast } from "../helper/toastHelper";

function Login() {
  const navigate = useNavigate();

  const { loginUserInfo, loginUser, handleChangeLogin } =
    useRegistrationAndAuthContext();

  const [showPassword, setShowPassword] = React.useState(false);

  const handlePasswordEyeIcone = () => setShowPassword(!showPassword);

  const handleSignIn = (e) => {
    e.preventDefault();

    const checkFieldsAreNotEmpty = Utils.checkRequiredFields(
      loginUserInfo,
      Utils.priorityOrderForLogin
    );

    if (checkFieldsAreNotEmpty) {
      return showToast(checkFieldsAreNotEmpty, LABEL.TOAST_TYPE.ERROR);
    }

    const checkEmailValidation = Utils.validateEmail(loginUserInfo.email);

    if (!checkEmailValidation) {
      return showToast(LABEL.SIGNUP.ERROR_MESSAGE.PEVE, LABEL.TOAST_TYPE.ERROR);
    }

    loginUser(successCallBack);
  };

  const successCallBack = () => navigate("/");

  return (
    <div>
      <div id='window'>
        <header class='gbl-header' id='header'>
          <div class='container'>
            <div class='gbl-header_left'>
              <div class='logo'>
                <Link to='/'>
                  <img src={myLogo} alt='Deadalus Logo' />
                </Link>
              </div>
            </div>
          </div>
        </header>

        <section class='forms-grid'>
          <div class='container'>
            <div class='forms-block'>
              <div class='forms-block_image'>
                <img src={headerFace} alt='pattern-frame' />
              </div>

              <div class='forms-block_form login-pass'>
                <div class='loginForm login-form'>
                  <header class='mb-4'>
                    <h4>Login To Your Account</h4>
                    <p>Please log in your account to continue</p>
                  </header>
                  <form
                    id='loginForm'
                    method='get'
                    action=''
                    role='form'
                    onSubmit={handleSignIn}>
                    <div class='row'>
                      <div class='col-xl-12'>
                        <div class='field'>
                          <label for='txtEmailLoginModal'>
                            Email Or Username *
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            id='txtEmailLoginModal'
                            name='email'
                            placeholder='Enter your email or username'
                            onChange={handleChangeLogin}
                          />
                        </div>
                        <div class='field'>
                          <label for='txtPassword'>Password *</label>
                          <div class='changePass-grid'>
                            <input
                              type={showPassword ? "text" : "password"}
                              class='form-control'
                              id='txtPassword'
                              name='password'
                              placeholder='Enter your password'
                              onChange={handleChangeLogin}
                            />
                            <span
                              onClick={handlePasswordEyeIcone}
                              className={`toggle-password ${
                                showPassword ? "tp-visible" : ""
                              }`}
                              data-target='txtPassword'>
                              <i
                                className={`fa-regular ${
                                  showPassword ? "fa-eye" : "fa-eye-slash"
                                }`}></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class='col-12 forgot-pass text-right'>
                        <a href='#' class='changeModal'>
                          Forgot Password?
                        </a>
                      </div>
                      <div class='col-12 mb-4'>
                        <button
                          class='cta-white full'
                          type='submit'
                          id='loginFormSubmit'>
                          Login
                        </button>
                      </div>
                      <div class='col-12 signup-text text-center'>
                        Don’t have an account?{" "}
                        <a href='/create-account' class=''>
                          Sign up
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Login;
