import React, { createContext, useContext, useState } from "react";
import SERVICES from "../services/services";
import { useDispatch, useSelector } from "react-redux";
import { updateQuesAns } from "../redux/userSlice";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");

  const handleAskQuestion = async () => {
    const result = await SERVICES.askQuestionService({ question });

    if (result?.statusCode === 200) {
      setQuestion("");
      dispatch(
        updateQuesAns({
          categories: result?.data?.categories ?? categories,
          quesAns: {
            question: result?.data?.question,
            answer: result?.data?.answer,
          },
          currentCategory: result?.data?.current_category,
        })
      );

      setIsLoading(false);
    }
  };

  const handleChangeQuestion = (value, speech = false) => {
    if (!speech) setQuestion(value);
    else setQuestion((prev) => prev + " " + value);
  };

  const value = {
    question,
    handleAskQuestion,
    handleChangeQuestion,
    setIsLoading,
    isLoading,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

// Custom hook to use the DashboardContext
export const useDashboardContext = () => useContext(DashboardContext);
