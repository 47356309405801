import React from "react";
import gptLogo from "../../assets/images/gpt-logo.png";

const QuesAns = ({ quesAns }) => {
  return (
    <>
      {quesAns.map((chat) => (
        <div className='srch_result_box'>
          <div className='srch_result_notice'>
            <p>{chat.question}</p>
          </div>

          <ul className='srch_result_list'>
            <li>
              <div className='icon'>
                <img src={gptLogo} alt='' />
              </div>
              <div className='srch_resultList_cont'>
                <h4>Daedalus</h4>
                <p>{chat.answer}</p>
              </div>
            </li>
          </ul>
        </div>
      ))}
    </>
  );
};

export default QuesAns;
