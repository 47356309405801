import React from "react";
import myLogo from "../assets/images/logo.svg";

export default function Footer() {
  return (
    <div>
      <footer className='footer-gbl'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-5'>
              <div className='footer-left'>
                <figure className='mb-4'>
                  <a href='javascript:void(0)'>
                    <img src={myLogo} alt='Deadalus Logo' />
                  </a>
                </figure>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.&nbsp;
                  <a href='#'>Read More</a>
                </p>
              </div>
            </div>

            <div className='col-lg-5'>
              <div className='row'>
                <div className='col-md-5'>
                  <nav className='footer-nav'>
                    <h6>Quick Links</h6>
                    <ul>
                      <li>
                        <a href='javasript:void(0)'>About us</a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>Faq’s</a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>Contact us</a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>Careers</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className='col-md-7'>
                  <nav className='footer-nav'>
                    <h6>Solutions</h6>
                    <ul>
                      <li>
                        <a href='javasript:void(0)'>AI Orchestration Chat</a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>
                          Routing orchestration leveraging API’s
                        </a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>Query History</a>
                      </li>
                      <li>
                        <a href='javasript:void(0)'>Local Storage</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className='footer-bottom'>
            <div className='row justify-content-between'>
              <div className='col-md-6'>
                <div className='small-txt'>
                  <p>© Copyright daedalus. All rights reserved.</p>
                </div>
              </div>
              <div className='col-md-6 social-media'>
                <ul>
                  <li>
                    <a href='javascript:void(0)' target='_blank'>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:void(0)' target='_blank'>
                      <i className='fab fa-youtube'></i>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:void(0)' target='_blank'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </li>
                  <li>
                    <a href='javascript:void(0)' target='_blank'>
                      <i className='fa-brands fa-x-twitter'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
