import React, { createContext, useContext, useState } from 'react';

// Create the context
const StepContext = createContext();

// Provider component to wrap around the app
export const StepProvider = ({ children }) => {
  const [mainStep, setMainStep] = useState(1); // Track the current main step (1 to 3)
  const [subStep, setSubStep] = useState(1);   // Track the current sub-step (1 to 7 in the 3rd main step)

  // Function to go to the next main step
  // const nextMainStep = () => {
  //   setMainStep(prevStep => (prevStep < 3 ? prevStep + 1 : prevStep));
  //   if (mainStep === 3) setSubStep(1); 
  // };

  const nextMainStep = () => {
    setMainStep(prevStep => (prevStep < 7 ? prevStep + 1 : prevStep));
    // if (mainStep === 3) setSubStep(1); 
  };

  // Function to go to the previous main step
  const prevMainStep = () => {
    setMainStep(prevStep => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  // Function to go to the next sub-step in the 3rd main step
  const nextSubStep = () => {
    if (mainStep === 3) {
      setSubStep(prevSubStep => (prevSubStep < 7 ? prevSubStep + 1 : prevSubStep));
    }
  };

  // Function to go to the previous sub-step in the 3rd main step
  const prevSubStep = () => {
    if (mainStep === 3) {
      setSubStep(prevSubStep => (prevSubStep > 1 ? prevSubStep - 1 : prevSubStep));
    }
  };

  return (
    <StepContext.Provider value={{ mainStep, subStep, nextMainStep, prevMainStep, nextSubStep, prevSubStep }}>
      {children}
    </StepContext.Provider>
  );
};

// Custom hook to use the StepContext
export const useStepContext = () => useContext(StepContext);
