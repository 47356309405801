import React from "react";
import myLogo from "../assets/images/logo.svg";
import crossIcon from "../assets/images/cross-icon-w.png";
import { Link, useNavigate } from "react-router-dom";
import { LABEL } from "../utils/constant";
import { showToast } from "../helper/toastHelper";
import { setAuthToken } from "../services/api";
import { useDispatch } from "react-redux";
import { resetState } from "../redux/userSlice";

export default function Header() {
  const dispatch = useDispatch();

  const token = localStorage.getItem("access_token");
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(resetState());
    setAuthToken();
    localStorage.removeItem("access_token");
    navigate("/login");
  };

  return (
    <div>
      <header class='gbl-header' id='header'>
        <div class='container'>
          <div class='gbl-header_left'>
            <div class='logo'>
              <Link to='/'>
                <img src={myLogo} alt='Deadalus Logo' />
              </Link>
            </div>

            <nav class='nav-grid'>
              <Link to='/' class='slider-close navToggle'>
                <img src={crossIcon} alt='' />
              </Link>
            </nav>
          </div>

          <div class='gbl-header_right'>
            {token ? (
              <div className='hdr_prof'>
                <span className='hdr_prof_nm'>{user.name}</span>

                <i
                  onClick={handleLogout}
                  href='create-account'
                  className='fa-solid fa-arrow-right-from-bracket'
                  style={{ color: "red", cursor: "pointer" }}></i>
              </div>
            ) : (
              <>
                <a href='create-account' class='cta-border'>
                  Sign up
                </a>
                <a href='login' class='cta-white'>
                  Login
                </a>
              </>
            )}

            <div class='toggleMenu navToggle'>
              <div class='hamburger-menu'>
                <div class='bar'></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
