import axios from 'axios';

// Base configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});

// Log to check if it's correctly set
console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);

// Function to set token when required
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Request interceptor for conditionally setting the token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token'); // Example token location
    if (token && config.headers.requiresAuth !== false) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, maybe redirect to login
      console.error('Unauthorized, please login again');
    }
    return Promise.reject(error);
  }
);

// Generic function for API calls
const request = {
  get: (url, config = {}) => api.get(url, config),
  post: (url, data, config = {}) => api.post(url, data, config),
  put: (url, data, config = {}) => api.put(url, data, config),
  delete: (url, config = {}) => api.delete(url, config),
};

const initializeApp = () => {
  // Check if a token is saved in localStorage
  const token = localStorage.getItem('access_token');

  if (token) {
    // Apply the token to axios if it exists
    setAuthToken(token);
  }
};

// Example usage for token-free calls:
// request.get('/public-endpoint', { headers: { requiresAuth: false } });

export { request, setAuthToken, initializeApp };
export default api;
