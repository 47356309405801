import { toast } from 'react-toastify';

let isToastVisible = false; // Flag to check if a toast is currently shown

export const showToast = (message, type) => {
  if (!isToastVisible) {
    isToastVisible = true; // Set the flag to true when a toast is displayed

    const toastOptions = {
      onClose: () => {
        isToastVisible = false; // Reset the flag when the toast is closed
      },
    };

    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'info':
        toast.info(message, toastOptions);
        break;
      case 'warn':
        toast.warn(message, toastOptions);
        break;
      default:
        toast(message, toastOptions);
        break;
    }
  }
};
