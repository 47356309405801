import React, { useState } from "react";
import headerFace from "../assets/images/header-face-img.png";
import aiChatImg from "../assets/images/ai-chat-img.svg";
import aiRoutingImg from "../assets/images/ai-routing-img.svg";
import aiQueryHistoryImg from "../assets/images/ai-query-history-img.svg";
import aiLocalStorageImg from "../assets/images/ai-local-storage-img.svg";
import graphicImg from "../assets/images/pattern-frame-img.svg";
import GuestLayout from "../layout/guestLayout";
import ChatBox from "../components/dashboard/ChatBox";
import { DashboardProvider } from "../context/DashboardContext";

function Home() {
  const token = localStorage.getItem("access_token");

  return (
    <div>
      <div id='window'>
        <GuestLayout>
          {token ? (
            <DashboardProvider>
              <ChatBox />
            </DashboardProvider>
          ) : (
            <>
              <section class='banner-home'>
                <div class='container'>
                  <div class='banner-home_grid animated'>
                    <div class='banner-home_image'>
                      <img src={headerFace} alt='pattern-frame' />
                    </div>
                    <div class='banner-home_intro'>
                      <h1>AI-Driven Chat, Perfectly Orchestrated</h1>
                      <h5>
                        Efficient Routing with Real-Time Query History and Local
                        Storage
                      </h5>

                      <a href='javascript:void(0)' class='cta-white'>
                        Explore Now
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              <div class='body-wrap'>
                <section class='gbl solutions-grid'>
                  <div class='container med'>
                    <header>
                      <small>What we do</small>
                      <h2>Daedalus Solutions</h2>
                    </header>
                    <main class='section-media'>
                      <ul>
                        <li>
                          <figure class='section-media_image'>
                            <img src={aiChatImg} alt='' />
                          </figure>
                          <article class='section-media_intro'>
                            <h3>AI Orchestration Chat</h3>
                            <p>
                              AI Orchestration Chat is a powerful solution that
                              leverages advanced AI to optimize conversations by
                              routing interactions seamlessly across APIs. With
                              features like query history and local storage, it
                              delivers efficient, real-time responses, enhancing
                              communication workflows and streamlining business
                              processes for smarter, more intuitive
                              interactions.
                            </p>
                            <a href='javascript:void(0)' class='cta-white'>
                              Read More
                            </a>
                          </article>
                        </li>
                        <li>
                          <figure class='section-media_image'>
                            <img src={aiRoutingImg} alt='' />
                          </figure>
                          <article class='section-media_intro'>
                            <h3>Routing orchestration leveraging API’s</h3>
                            <p>
                              Routing Orchestration leveraging APIs enables
                              seamless communication between systems by
                              intelligently directing data and requests across
                              multiple APIs. It optimizes workflows, enhances
                              efficiency, and ensures real-time interaction by
                              automating complex processes, making it ideal for
                              businesses looking to streamline operations and
                              improve responsiveness.
                            </p>
                            <a href='javascript:void(0)' class='cta-border'>
                              Read More
                            </a>
                          </article>
                        </li>
                        <li>
                          <figure class='section-media_image'>
                            <img src={aiQueryHistoryImg} alt='' />
                          </figure>
                          <article class='section-media_intro'>
                            <h3>Query History</h3>
                            <p>
                              Query History is a feature that stores and tracks
                              past interactions, enabling quick reference and
                              analysis of previous queries. It enhances
                              efficiency by allowing users to review, manage,
                              and learn from past data, ensuring smarter
                              decision-making and faster resolutions in future
                              interactions.
                            </p>
                            <a href='javascript:void(0)' class='cta-border'>
                              Read More
                            </a>
                          </article>
                        </li>
                        <li>
                          <figure class='section-media_image'>
                            <img src={aiLocalStorageImg} alt='' />
                          </figure>
                          <article class='section-media_intro'>
                            <h3>Local Storage</h3>
                            <p>
                              Local Storage is a feature that allows data to be
                              stored directly on a user's device, enabling quick
                              access and retrieval without relying on external
                              servers. It enhances performance, improves data
                              availability, and provides offline access, making
                              it ideal for applications that require speed,
                              privacy, and continuity.
                            </p>
                            <a href='javascript:void(0)' class='cta-border'>
                              Read More
                            </a>
                          </article>
                        </li>
                      </ul>
                    </main>
                  </div>
                </section>

                <section class='gbl graphic-image text-center'>
                  <img src={graphicImg} alt='' />
                </section>
              </div>
            </>
          )}
        </GuestLayout>
      </div>
    </div>
  );
}

export default Home;
