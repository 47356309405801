const section1Questions = [
  {
    id: 1,
    question: "1. If A = B and B = C, what can be inferred about A and C?",
    options: ["A = C", "A > C", "C > A", "Cannot be determined"],
  },
  {
    id: 2,
    question:
      "2. How often do you reflect on your feelings during a challenging situation?",
    options: ["Often", "Sometimes", "Rarely", "Never"],
  },
  {
    id: 3,
    question:
      "3. How comfortable are you taking the lead in projects or discussions?",
    options: [
      "Very comfortable",
      "Somewhat comfortable",
      "Uncomfortable",
      "Prefer others lead",
    ],
  },
  {
    id: 4,
    question:
      "4. When a friend shares a problem, how do you typically respond?",
    options: [
      "Just listen",
      "Offer solutions",
      "Share a similar experience",
      "Distract them",
    ],
  },
  {
    id: 5,
    question:
      "5. Which number should come next in the sequence? 2, 6, 12, 20, 30, …?",
    options: ["42", "36", "38", "40"],
  },
  {
    id: 6,
    question:
      "6. In a group setting, do you generally find it easy to pick up on others' emotional cues?",
    options: ["Yes", "Sometimes", "Rarely", "Never"],
  },
  {
    id: 7,
    question:
      "7. Identify the next shape in this pattern: Circle, Square, Triangle, Circle, Square, …?",
    options: ["Triangle", "Circle", "Square", "Hexagon"],
  },
  {
    id: 8,
    question: "8. What would motivate you to help a stranger in need?",
    options: [
      "Compassion",
      "Sense of duty",
      "Personal satisfaction",
      "Only if I have time",
    ],
  },
  {
    id: 9,
    question: "9. If you found a wallet with money in it, what would you do?",
    options: [
      "Return it as is",
      "Take the money but return the wallet",
      "Keep it",
      "Return it if convenient",
    ],
  },
  {
    id: 10,
    question: "10. What is 15% of 200?",
    options: ["30", "25", "20", "15"],
  },
];

const section2Questions = [
  {
    id: 11,
    question: "11. How do you handle last-minute changes to a plan?",
    options: [
      "Adapt easily",
      "Need some adjustment time",
      "Feel stressed",
      "Become frustrated",
    ],
  },
  {
    id: 12,
    question: "12. How important are details to you in planning or working?",
    options: [
      "Very important",
      "Somewhat important",
      "Not very important",
      "Depends on the situation",
    ],
  },
  {
    id: 13,
    question:
      "13. When someone disagrees with you, what’s your initial reaction?",
    options: ["Listen", "Defend", "Ignore", "Try to change their mind"],
  },
  {
    id: 14,
    question:
      "14. If it takes 5 machines 5 minutes to make 5 parts, how long would it take 100 machines to make 100 parts?",
    options: ["5 minutes", "10 minutes", "20 minutes", "100 minutes"],
  },
  {
    id: 15,
    question: "15. When faced with failure, what’s your first response?",
    options: [
      "Learn from it",
      "Try to ignore it",
      "Feel discouraged",
      "Try again immediately",
    ],
  },
  {
    id: 16,
    question: "16. Which work environment suits you best?",
    options: [
      "Structured & orderly",
      "Collaborative & social",
      "Competitive & fast-paced",
      "Flexible & dynamic",
    ],
  },
  {
    id: 17,
    question: "17. Which word does not belong? Bark, Leaf, Root, Blossom.",
    options: ["Bark", "Leaf", "Blossom", "Root"],
  },
  {
    id: 18,
    question:
      "18. When a friend shares a problem, how do you typically respond?",
    options: [
      "Just listen",
      "Offer solutions",
      "Share a similar experience",
      "Distract them",
    ],
  },
  {
    id: 19,
    question:
      "19. If all roses are flowers, and some flowers fade quickly, which of the following is true?",
    options: [
      "Some roses fade quickly.",
      "All flowers fade quickly",
      "All roses are flowers that fade quickly",
      "None fade quickly.",
    ],
  },
  {
    id: 20,
    question:
      "20. How often do you help others, even if it means going out of your way?",
    options: ["Often", "Occasionally", "Rarely", "Never"],
  },
];

const section3Questions = [
  {
    id: 21,
    question: "21. How do you feel about routine and stability in your life?",
    options: ["Love it", "Neutral", "Prefer variety", "Dislike routine"],
  },
  {
    id: 22,
    question: "22. Which drives you most?",
    options: ["Security", "Adventure", "Achievement", "Stability"],
  },
  {
    id: 23,
    question:
      "23. How comfortable are you sharing personal feelings with others?",
    options: [
      "Very comfortable",
      "Sometimes comfortable",
      "Rarely comfortable",
      "Not comfortable",
    ],
  },
  {
    id: 24,
    question: "24. When setting goals, which factor is most important?",
    options: [
      "Personal achievement",
      "Helping others",
      "Following principles",
      "Financial security",
    ],
  },
  {
    id: 25,
    question:
      "25. If a clock shows 3:15, what angle is between the hour and minute hand?",
    options: ["52.5 degrees", "75 degrees", "90 degrees", "60 degrees"],
  },
  {
    id: 26,
    question:
      "26. How do you handle situations that conflict with your values?",
    options: ["Confront them", "Adapt if needed", "Avoid", "Rationalize"],
  },
  {
    id: 27,
    question: "27. How often do you seek feedback to improve yourself?",
    options: ["Regularly", "Occasionally", "Rarely", "Never"],
  },
  {
    id: 28,
    question: "28. Do you internalize negative feedback from others?",
    options: ["Often", "Sometimes", "Rarely", "Never"],
  },
  {
    id: 29,
    question:
      "29. If John is taller than Sarah and Sarah is taller than Tom, who is the shortest?",
    options: ["John", "Sarah", "Tom", "Cannot be determined"],
  },
  {
    id: 30,
    question: "30. How do you typically address conflicts within a team?",
    options: [
      "Attempt mediation",
      "Ignore the conflict",
      "Choose a side",
      "Raise the issue with both parties",
    ],
  },
];

const section4Questions = [
  {
    id: 31,
    question: "31. Cat is to Kitten as Dog is to:",
    options: ["Puppy", "Fox", "Cub", "Pup"],
  },
  {
    id: 32,
    question: "32. What’s your main focus in personal growth?",
    options: ["Character", "Empathy", "Success", "Freedom"],
  },
  {
    id: 33,
    question: "33. How likely are you to admit a mistake publicly?",
    options: [
      "Very likely",
      "Somewhat likely",
      "Not likely",
      "Only if necessary",
    ],
  },
  {
    id: 34,
    question: "34. Which drives you most?",
    options: ["Security", "Adventure", "Achievement", "Stability"],
  },
  {
    id: 35,
    question: "35. Do you prefer predictable or varied tasks?",
    options: ["Predictable", "Varied", "Goal-oriented", "Well-defined"],
  },
  {
    id: 36,
    question: "36. What would motivate you to help a stranger in need?",
    options: [
      "Compassion",
      "Sense of duty",
      "Personal satisfaction",
      "Only if I have time",
    ],
  },
  {
    id: 37,
    question:
      "37. I am taken from a mine and shut up in a wooden case, from which I am never released, and yet I am used by almost every person. What am I?",
    options: ["Pencil", "Coal", "Chalk", "Eraser"],
  },
  {
    id: 38,
    question:
      "38. When a friend shares a problem, how do you typically respond?",
    options: [
      "Just listen",
      "Offer solutions",
      "Share a similar experience",
      "Distract them",
    ],
  },
  {
    id: 39,
    question: "39. Which work environment suits you best?",
    options: [
      "Structured and orderly",
      "Collaborative and social",
      "Competitive and fast-paced",
      "Flexible and dynamic",
    ],
  },
  {
    id: 40,
    question: "40. How important are details to you in planning or working?",
    options: [
      "Very important",
      "Somewhat important",
      "Not very important",
      "Depends on the situation",
    ],
  },
];

const section5Questions = [
  {
    id: 41,
    question:
      "41. You have 8 balls; 7 weigh the same, but one is heavier. What’s the minimum number of weighings needed to find the heaviest ball?",
    options: ["2", "3", "4", "5"],
  },
  {
    id: 42,
    question:
      "42. If it takes 5 machines 5 minutes to make 5 parts, how long would it take 100 machines to make 100 parts?",
    options: ["5 minutes", "10 minutes", "20 minutes", "100 minutes"],
  },
  {
    id: 43,
    question: "43. When setting goals, which factor is most important?",
    options: [
      "Personal achievement",
      "Helping others",
      "Following principles",
      "Financial security",
    ],
  },
  {
    id: 44,
    question:
      "44. How often do you help others, even if it means going out of your way?",
    options: ["Often", "Occasionally", "Rarely", "Never"],
  },
  {
    id: 45,
    question: "45. Would you break a rule to achieve success?",
    options: [
      "Yes, if minor",
      "Only if necessary",
      "No, never",
      "Depends on the circumstances",
    ],
  },
  {
    id: 46,
    question: "46. How do you typically express frustration?",
    options: [
      "Openly, with words",
      "By isolating myself",
      "Calmly and composed",
      "React impulsively",
    ],
  },
  {
    id: 47,
    question:
      "47. If a plant dies when there is no sunlight, what is a necessary condition for its survival?",
    options: ["Water", "Fertilizer", "Sunlight", "Shade"],
  },
  {
    id: 48,
    question: "48. How do you respond to criticism?",
    options: [
      "Consider it thoughtfully",
      "Accept it and move on",
      "Dismiss it",
      "Feel defensive",
    ],
  },
  {
    id: 49,
    question: "49. When faced with failure, what’s your first response?",
    options: [
      "Learn from it",
      "Try to ignore it",
      "Feel discouraged",
      "Try again immediately",
    ],
  },
  {
    id: 50,
    question: "50. How do you feel about routine and stability in your life?",
    options: ["Love it", "Neutral", "Prefer variety", "Dislike routine"],
  },
];

function findMissingNumber(data, start, end) {
  // Create an array of numbers from start to end
  const expectedNumbers = Array.from(
    { length: end - start + 1 },
    (_, i) => i + start
  );

  // Extract keys from the object and convert them to numbers
  const existingNumbers = Object.keys(data).map(Number);

  // Find missing numbers
  for (const number of expectedNumbers) {
    if (!existingNumbers.includes(number)) {
      return number; // Return the first missing number
    }
  }

  return false; // Return false if no number is missing
}

function findBlankCategory(data) {
  return data.some((v) => !v);
}

function checkPasswordMatch(password, confirmPassword) {
  if (password === confirmPassword) {
    return true; // Passwords match
  } else {
    return false; // Passwords do not match
  }
}

const priorityOrderForSignup = [
  "name",
  "email",
  "dob",
  "interest",
  "profession",
  "password",
  "confirmPassword",
];

const validationKeysAndValues = {
  name: "Name",
  email: "Email",
  dob: "Date of birth",
  interest: "Interest",
  profession: "Profession",
  password: "Password",
  confirmPassword: "Confirm password",
};

const priorityOrderForLogin = ["email", "password"];

function checkRequiredFields(fields, priorityOrder) {
  for (const field of priorityOrder) {
    if (fields[field] === "") {
      return `${validationKeysAndValues[field]} is required`;
    }
  }
  return false;
}

const professions = [
  "Software Engineer",
  "Data Scientist",
  "Product Manager",
  "Marketing Specialist",
  "Graphic Designer",
  "Mechanical Engineer",
  "Civil Engineer",
  "Teacher",
  "Accountant",
  "Doctor",
  "Nurse",
  "Pharmacist",
  "Architect",
  "Lawyer",
  "Financial Analyst",
  "Consultant",
  "Project Manager",
  "Social Worker",
  "Human Resources Manager",
  "Research Scientist",
  "Electrician",
  "Plumber",
  "Chef",
  "Sales Manager",
  "Real Estate Agent",
  "Journalist",
  "Psychologist",
  "Biologist",
  "Chemist",
  "Dentist",
  "Other",
];

function validatePassword(password) {
  // Regular expression for password validation
  const regex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:;<>,.?~`|\\[\]\/\-])[A-Za-z\d!@#$%^&*()_+{}:;<>,.?~`|\\[\]\/\-]{8,}$/;

  // Test the password against the regex
  return regex.test(password);
}

function validateEmail(email) {
  // Regular expression for email validation
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Test the email against the regex
  return regex.test(email);
}

const ISOStringdate = (date) => {
  const dob = new Date(date);
  const isoDate = dob.toISOString();
  return isoDate;
};

const Utils = {
  section1Questions,
  section2Questions,
  section3Questions,
  section4Questions,
  section5Questions,
  findMissingNumber,
  checkPasswordMatch,
  checkRequiredFields,
  professions,
  ISOStringdate,
  validatePassword,
  validateEmail,
  priorityOrderForSignup,
  priorityOrderForLogin,
  findBlankCategory,
};

export const LABEL = {
  TOAST_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARN: "warn",
  },
  SIGNUP: {
    ERROR_MESSAGE: {
      PEVE: "Please enter valid Email.",
      PTSP: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
      PACPNS: "Password and Confirm password value should be same",
    },
  },
  CHAT: {
    ERROR_MESSAGE: {
      SNSB: "Speech not supported in this browser.",
    },
  },
};

export default Utils;
