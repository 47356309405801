import React, { useEffect } from "react";
import withCreateAccountLayout from "../../HOC/withCreateAccountLayout";
import { useNavigate } from "react-router-dom";
import { useStepContext } from "../../context/StepContext";
import Utils, { LABEL } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import { useRegistrationAndAuthContext } from "../../context/RegistrationAndAuthContext";

function RegistrationStepSeven() {
  const navigate = useNavigate();
  const { prevMainStep } = useStepContext();
  const { handleAnswerChange, answers, submitAnswers } =
    useRegistrationAndAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStepSix = (e) => {
    e.preventDefault();
    const isAnyQusestionIsSkiped = Utils.findMissingNumber(answers, 41, 50);
    if (isAnyQusestionIsSkiped) {
      return showToast(
        `Please select answer for ${isAnyQusestionIsSkiped}.`,
        LABEL.TOAST_TYPE.ERROR
      );
    }

    submitAnswers(HandleSignUp);
  };

  const HandleSignUp = () => {
    navigate("/");
  };

  return (
    <div className='forms'>
      <form id='' method='get' action='' role='form' onSubmit={handleStepSix}>
        <div className='row'>
          <div className='col-xl-12'>
            {Utils.section5Questions.map((question) => (
              <div className='user-assessment_qua_ans'>
                <h6 key={question.id}>
                  {question.question}&nbsp;
                  <span style={{ color: "red" }}>*</span>
                </h6>
                <ul>
                  {question.options.map((option, index) => (
                    <li key={`${question.id}_${index}`}>
                      <label className='form-radio'>
                        <input
                          type='radio'
                          name={`question_${question.id}`}
                          className='radiobox'
                          onChange={() =>
                            handleAnswerChange(question.id, option)
                          }
                          checked={answers[question.id] === option}
                        />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className='col-xl-7 mb-4 d-flex gap-3 back-btt-group'>
            <a
              onClick={(e) => {
                e.preventDefault();
                prevMainStep();
              }}
              href='javascript:void(0)'
              className='cta-white small'>
              <i className='fa-solid fa-play'></i>
            </a>
            <button className='cta-white full' type='submit' id=''>
              Sign Up
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withCreateAccountLayout(RegistrationStepSeven, {
  title: "User Assessment Test",
  isFullWidth: true,
  mainStep: 7,
});
