import { createSlice } from "@reduxjs/toolkit";

const initState = {
  categories: [],
  quesAns: [],
  currentCategory: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateQuesAns: (state, action) => {
      state.categories = action.payload.categories;
      state.quesAns.push(action.payload.quesAns);
      state.currentCategory = action.payload.currentCategory;
    },
    resetState: (state, action) => {
      state.categories = [];
      state.currentCategory = null;
      state.quesAns = [];
    },
  },
});

export const { updateCategories, updateQuesAns, resetState } =
  userSlice.actions;

export default userSlice.reducer;
