import React, { useEffect } from "react";
import myLogo from "../../assets/images/logo.svg";
import withCreateAccountLayout from "../../HOC/withCreateAccountLayout";
import { useStepContext } from "../../context/StepContext";
import Utils, { LABEL } from "../../utils/constant";
import { useRegistrationAndAuthContext } from "../../context/RegistrationAndAuthContext";
import { showToast } from "../../helper/toastHelper";

function RegistrationStepThree() {
  const { nextMainStep } = useStepContext();
  const {
    categories,
    handleAnswerChange,
    answers,
    handleAddCategory,
    handleDelCategory,
    handleUpdateCategory,
  } = useRegistrationAndAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStepThree = (e) => {
    e.preventDefault();
    const isAnyQusestionIsSkiped = Utils.findMissingNumber(answers, 1, 10);
    const isBlankCategory = Utils.findBlankCategory(categories);
    if (isBlankCategory) {
      return showToast(`Please fill all categories`, LABEL.TOAST_TYPE.ERROR);
    } else if (isAnyQusestionIsSkiped) {
      return showToast(
        `Please select answer for ${isAnyQusestionIsSkiped}.`,
        LABEL.TOAST_TYPE.ERROR
      );
    }
    nextMainStep();
  };

  return (
    <div className='forms'>
      <form id='' method='get' action='' role='form' onSubmit={handleStepThree}>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row'>
              <h6>Categories</h6>

              <div className="d-flex flex-column">
                {categories.map((category, index) => (
                  <div className='categori_fild_wrap' key={index}>
                    <div className="categori_fildBox">
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter Category Name'
                        value={category}
                        onChange={(event) =>
                          handleUpdateCategory(event.target.value, index)
                        }
                      />
                    </div>
                    <button type="button" className="deleteBtn" onClick={() => handleDelCategory(index)}>
                      <i className='fa-regular fa-trash-can'></i>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-xl-6'>
                <button
                  type='button'
                  className='addmore_btn'
                  onClick={handleAddCategory}>
                  Add more
                </button>
              </div>
            </div>
            {Utils.section1Questions.map((question) => (
              <div className='user-assessment_qua_ans'>
                <h6 key={question.id}>
                  {question.question}&nbsp;
                  <span style={{ color: "red" }}>*</span>
                </h6>
                <ul>
                  {question.options.map((option, index) => (
                    <li key={`${question.id}_${index}`}>
                      <label className='form-radio'>
                        <input
                          type='radio'
                          name={`question_${question.id}`}
                          className='radiobox'
                          onChange={() =>
                            handleAnswerChange(question.id, option)
                          }
                          checked={answers[question.id] === option}
                        />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className='col-xl-7 mb-4'>
            <button className='cta-white full' type='submit' id=''>
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withCreateAccountLayout(RegistrationStepThree, {
  title: "User Assessment Test",
  isFullWidth: true,
  mainStep: 3,
});
