import React from "react";
import logo from "../../assets/images/logo.svg";

import { useSelector } from "react-redux";
import { useDashboardContext } from "../../context/DashboardContext";
import SearchBox from "./SearchBox";
import QuesAns from "./QuesAns";
import loaderImg from "../../assets/images/Rolling@1x-1.0s-200px-200px.gif";

export default function ChatBox() {
  const [categories, quesAns, currentCategory] = useSelector((state) => [
    state.categories,
    state.quesAns,
    state.currentCategory,
  ]);
  const {
    handleAskQuestion,
    handleChangeQuestion,
    setIsLoading,
    isLoading,
    question,
  } = useDashboardContext();

  const handleSubmitQuestion = () => {
    setIsLoading(true);
    handleAskQuestion();
  };

  return (
    <div className='dashboard_wrapper'>
      <aside className='main_sidebar'>
        <div className='sidebarLogo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='prev_history_list_box'>
          <div className='rev_history_list_row'>
            {categories.map((category, index) => (
              <div className='rev_history_listItm' key={index}>
                <label
                  className={category === currentCategory ? "selected" : ""}>
                  {category}
                </label>
              </div>
            ))}
          </div>
        </div>
      </aside>
      <aside className='main_content'>
        <div className='searchBox_wrap'>
          <div className='searchBox'>
            {!!quesAns.length ? (
              <QuesAns quesAns={quesAns} />
            ) : (
              <h2>What can I help with?</h2>
            )}
            {isLoading && (
              <div className='loader-box'>
                <div className='loader'>
                  <img src={loaderImg} alt='' />
                </div>
              </div>
            )}
            <SearchBox
              isLoading={isLoading}
              question={question}
              handleSubmitQuestion={handleSubmitQuestion}
              handleChangeQuestion={handleChangeQuestion}
            />
          </div>
        </div>
      </aside>
    </div>
  );
}
