import { showToast } from "../helper/toastHelper";
import { LABEL } from "../utils/constant";
import ENDPOINT from "../utils/endpoint";
import { request } from "./api";

const createAccount = async (accountData) => {
  try {
    const response = await request.post(ENDPOINT.SIGNUP, accountData, {
      headers: {
        requiresAuth: false,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating account:",
      error.response ? error.response.data : error.message
    );
    showToast(
      error?.response?.data?.message || error?.message,
      LABEL.TOAST_TYPE.ERROR
    );
    return error;
    // throw error;
  }
};

const loginUserAccountService = async (accountData) => {
  try {
    const response = await request.post(ENDPOINT.SIGNIN, accountData, {
      headers: {
        requiresAuth: false,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating account:",
      error.response ? error.response.data : error.message
    );
    showToast(
      error?.response?.data?.message || error?.message,
      LABEL.TOAST_TYPE.ERROR
    );
    return error;
    // throw error;
  }
};

const askQuestionService = async (questionData) => {
  try {
    const response = await request.post(ENDPOINT.ASKQUESTION, questionData, {
      headers: {
        requiresAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching answer:",
      error.response ? error.response.data : error.message
    );
    showToast(
      error?.response?.data?.message || error?.message,
      LABEL.TOAST_TYPE.ERROR
    );
    return error;
    // throw error;
  }
};

const SERVICES = {
  createAccount,
  loginUserAccountService,
  askQuestionService,
};

export default SERVICES;
