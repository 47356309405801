import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function GuestLayout({ children }) {
  const token = localStorage.getItem("access_token");
  return (
    <div>
      <Header />
      {children}
      {!token && <Footer />}
    </div>
  );
}

export default GuestLayout;
