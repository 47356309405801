import React from "react";
import { useStepContext } from "../context/StepContext";
import RegistrationStepOne from "../components/RegistrationSteps/RegistrationStepOne";
import RegistrationStepThree from "../components/RegistrationSteps/RegistrationStepThree";
import RegistrationStepFour from "../components/RegistrationSteps/RegistrationStepFour";
import RegistrationStepFive from "../components/RegistrationSteps/RegistrationStepFive";
import RegistrationStepSix from "../components/RegistrationSteps/RegistrationStepSix";
import RegistrationStepSeven from "../components/RegistrationSteps/RegistrationStepSeven";

const steps = [
  <RegistrationStepOne />,
  <RegistrationStepThree />,
  <RegistrationStepFour />,
  <RegistrationStepFive />,
  <RegistrationStepSix />,
  <RegistrationStepSeven />,
];

export default function Signup() {
  const { mainStep } = useStepContext();
  return (
    <div>
      {steps[mainStep - 1]}
      <div></div>
    </div>
  );
}
