import { RegistrationAndAuthProvider } from "./context/RegistrationAndAuthContext";
import { StepProvider } from "./context/StepContext";
import HybridRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "./services/api";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    initializeApp(); // Run on app load
  }, []);

  return (
    <div className='App'>
      <ToastContainer />
      <StepProvider>
        <RegistrationAndAuthProvider>
          <HybridRoutes />
        </RegistrationAndAuthProvider>
      </StepProvider>
    </div>
  );
}

export default App;
