import React from "react";
import myLogo from "../assets/images/logo.svg";
import headerFace from "../assets/images/header-face-img.png";
import { Link } from "react-router-dom";
const WithCreateAccountLayout = (
  WrappedComponent,
  { title, subTitle, isFullWidth, mainStep = 1 }
) => {
  return function LayoutWrapper(props) {
    return (
      <div>
        <div id='window'>
          <header className='gbl-header' id='header'>
            <div className='container'>
              <div className='gbl-header_left'>
                <div className='logo'>
                  <Link to='/'>
                    <img src={myLogo} alt='Deadalus Logo' />
                  </Link>
                </div>
              </div>
            </div>
          </header>

          <section className='forms-grid'>
            <div className='container'>
              <div className={isFullWidth ? "forms-block full" : "forms-block"}>
                {!isFullWidth && (
                  <div className='login-block_image'>
                    <img src={headerFace} alt='pattern-frame' />
                  </div>
                )}

                <div className='forms-block_form create-account user-assessment'>
                  <header>
                    <h4>{title}</h4>
                    <p>{subTitle}</p>

                    <div className='form-step'>
                      <div
                        className={`form-step_block form-step_one  ${
                          mainStep > 1 ? "active" : ""
                        }`}>
                        <div className='circle'>01</div>
                        <div className='label'>Personal Information</div>
                      </div>

                      <div
                        className={`form-step_block form-step_one ${
                          mainStep === 7 ? "active" : ""
                        }`}>
                        <div className='circle'>02</div>
                        <div className='label'>User Assessment Test</div>
                      </div>
                    </div>
                  </header>

                  <WrappedComponent {...props} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };
};

export default WithCreateAccountLayout;
