import React, { useRef } from "react";
import withCreateAccountLayout from "../../HOC/withCreateAccountLayout";
import { useStepContext } from "../../context/StepContext";
import { useRegistrationAndAuthContext } from "../../context/RegistrationAndAuthContext";
import Utils, { LABEL } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import moment from "moment";

function RegistrationStepOne() {
  const { nextMainStep } = useStepContext();
  const { userInfo, handleChange } = useRegistrationAndAuthContext();

  const dateInputRef = useRef(null);

  const [showPassword, setShowPassword] = React.useState(false);

  const today = moment();
  const maxDate = today.subtract(18, 'years').format("YYYY-MM-DD");

  const handleClick = (event) => {
    dateInputRef?.current?.showPicker(); // Date input ko focus karna
  };


  const handlePasswordEyeIcone = () => setShowPassword(!showPassword);

  const handleSignupFirstStape = (e) => {
    e.preventDefault();
    const checkFieldsAreNotEmpty = Utils.checkRequiredFields(userInfo, Utils.priorityOrderForSignup);

    if (checkFieldsAreNotEmpty) {
      return showToast(checkFieldsAreNotEmpty, LABEL.TOAST_TYPE.ERROR)
    }

    const checkEmailValidation = Utils.validateEmail(userInfo.email);

    if (!checkEmailValidation) {
      return showToast(LABEL.SIGNUP.ERROR_MESSAGE.PEVE, LABEL.TOAST_TYPE.ERROR)
    }

    const checkPasswordStrength = Utils.validatePassword(userInfo.password);

    if (!checkPasswordStrength) {
      return showToast(LABEL.SIGNUP.ERROR_MESSAGE.PTSP, LABEL.TOAST_TYPE.ERROR)
    }

    const checkPasswordMatch = Utils.checkPasswordMatch(userInfo.password, userInfo.confirmPassword)

    if (!checkPasswordMatch) {
      return showToast(LABEL.SIGNUP.ERROR_MESSAGE.PACPNS, LABEL.TOAST_TYPE.ERROR)
    }

    nextMainStep();
  }

  return (
    <div className="forms">
      <form id="createAccountForm" method="get" action="" role="form" onSubmit={handleSignupFirstStape}>
        <div className="row">
          <div className="col-xl-12">
            <div className="field">
              <label for="txtName">Name *</label>
              <input
                type="text"
                className="form-control"
                id="txtName"
                name="name"
                placeholder="Enter Your Name"
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label for="txtEmail">Email *</label>
              <input
                type="text"
                className="form-control"
                id="txtEmail"
                name="email"
                placeholder="Enter Your Email"
                onChange={handleChange}
              />
            </div>
            <div className="field datepicker-grid">
              <label for="txtDateofBirth">Date of Birth*</label>
              <div className="input-group date" id="datepicker" style={{ width: '100%' }} onClick={handleClick}>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  id="txtDateofBirth"
                  ref={dateInputRef}
                  placeholder="mm/dd/yyyy"
                  onChange={handleChange}
                  max={maxDate}
                />
                <span className="input-group-append">
                  <span className="input-group-text">
                    <i className="fa fa-calendar"></i>
                  </span>
                </span>
              </div>
            </div>
            <div className="field">
              <label for="txtInterest">Interest *</label>
              <input
                type="text"
                className="form-control"
                id="txtInterest"
                name="interest"
                placeholder="Enter Your Interest"
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label for="selectProfession">Profession *</label>
              <select
                className="form-control"
                id="selectProfession"
                name="profession"
                onChange={handleChange}
              >
                <option value={""}>Select Profession</option>
                {
                  Utils.professions.map((profession) => (
                    <option value={profession}>{profession}</option>
                  ))
                }

              </select>
            </div>
            <div className="field">
              <label for="txtPassword">Password *</label>
              <div className="changePass-grid">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="txtPassword"
                  name="password"
                  placeholder="Enter Your Password"
                  onChange={handleChange}

                />
                <span onClick={handlePasswordEyeIcone} className={`toggle-password ${showPassword ? "tp-visible" : ""}`} data-target="txtPassword">
                  <i className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label for="txtConfirmPassword">Confirm Password *</label>
              <div className="changePass-grid">
                <input
                  type="text"
                  className="form-control"
                  id="txtConfirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Your Password"
                  onChange={handleChange}

                />
                {/* <span
                  className="toggle-password"
                  data-target="txtConfirmPassword"
                >
                  <i className="fa-regular fa-eye-slash"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="col-12">
            <button
              className="cta-white full"
              type="submit"
              id="createAccountSubmit"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withCreateAccountLayout(RegistrationStepOne, {
  title: "Create Account",
  subTitle: "Please create your account to continue.",
  mainStep: 1,
});
