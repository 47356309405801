import "./assets/css/bootstrap.min.css";
import "./assets/css/deadalus_gbl.css";

import { Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import Login from "./page/Login";
import Signup from "./page/Signup";

function HybridRoutes() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/create-account' element={<Signup />} />
      </Routes>
    </>
  );
}

export default HybridRoutes;
